import React, {createContext, useState, useReducer} from 'react';
import { EmailReducer } from '../reducers/EmailReducer';

export const EmailContext = createContext()

const EmailContextProvider = (props) => {
    const [Emails, dispatch] = useReducer(EmailReducer, [])
    const [_Emails, _dispatch] = useState([]);
    
    return (
        <EmailContext.Provider value={{Emails, dispatch, _Emails, _dispatch}}>
            { props.children }
        </EmailContext.Provider>
    );
}

export default EmailContextProvider;