import { at } from "lodash";

export const PostReducer = (state, action) => {
    switch(action.type){
         case 'SAVE':
            // setDefaultState(action.payload)
            return [action.payload];
        case 'FILTERED_BY_PAGES':
            return [...state, {}];
        case 'FILTERED_BY_CONTENT':
            return [...state, {}];
        case 'FILTERE_BY_TAGS':
            return [...state, {}];
        case 'FILTERED_BY_MARK':
            console.log(state,'kklklklllllllll')
            const  { data } = action;
            //const data = state[0][action.payload]; // payload contains index
            const _data = data[action.payload];
            const { SelectedOpt } = action; // selected Option
            const filteration = filterByMarkAnUnmark(_data,SelectedOpt)
            return filteration;
        default:
            return state[0];
    }
}


const filterByMarkAnUnmark = (data,SelectedOpt) => {
    let filtereData = data;
    if(SelectedOpt === 'marked'){
        filtereData = data.map((element) => {
            let v = element.comments.length > 0 && element.comments.find(
            (each) =>
                !each.from ||
                (each.from && !each.from.name) ||
                (each.from &&
                each.from.name &&
                each.from.name !== element.pageName)
            )
            let index = v && v.isMarked
            if(index === true){
            return {...element}
            }
        })
    }else{
        filtereData = data.map((element) => {
            let v = element.comments.find(
            (each) =>
                !each.from ||
                (each.from && !each.from.name) ||
                (each.from &&
                each.from.name &&
                each.from.name !== element.pageName)
            )
            let index = v && v.isMarked
            if(index != true){
            return {...element}
            }
        })
    }
    return filtereData;
}