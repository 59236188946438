import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import App from './App/index';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import config from './config';
import Reactotron from 'reactotron-react-js'
import PostConextProvider from './contexts/PostContext';
import ChatContextProvider from './contexts/ChatContext';
import AdContextProvider from './contexts/AdsContext';
import EmailContextProvider from './contexts/EmailContext';
import InstaConextProvider from './contexts/InstaContext';
import ReviewContextProvider from './contexts/ReviewsContext';

Reactotron
  .configure() // we can use plugins here -- more on this later
  .connect() // let's connect!
const store = createStore(reducer);

const app = (
    <Provider store={store}>
        <ChatContextProvider>
            <PostConextProvider>
                <AdContextProvider>
                    <EmailContextProvider>
                        <InstaConextProvider>
                            <ReviewContextProvider>
                                <BrowserRouter basename={config.basename}>
                                {/* basename="/datta-able" */}
                                    <App />
                                </BrowserRouter>
                            </ReviewContextProvider>
                        </InstaConextProvider>
                    </EmailContextProvider>
                </AdContextProvider>
            </PostConextProvider>
        </ChatContextProvider>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
