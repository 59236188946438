import React, {createContext, useState, useReducer} from 'react';
import { ChatReducer } from '../reducers/ChatReducer';

export const ChatContext = createContext()

const ChatContextProvider = (props) => {
    const [Chats, dispatch] = useReducer(ChatReducer, [])
    const [_Chats, _dispatch] = useState([]);
    
    return (
        <ChatContext.Provider value={{Chats, dispatch, _Chats, _dispatch}}>
            { props.children }
        </ChatContext.Provider>
    );
}

export default ChatContextProvider;