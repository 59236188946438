import React, {createContext, useState, useReducer} from 'react';
import { AdReducer } from '../reducers/AdReducer';

export const AdContext = createContext()

const AdContextProvider = (props) => {
    const [Ads, dispatch] = useReducer(AdReducer, [])
    const [_Ads, _dispatch] = useState([]);
    
    return (
        <AdContext.Provider value={{Ads, dispatch, _Ads, _dispatch}}>
            { props.children }
        </AdContext.Provider>
    );
}

export default AdContextProvider;