import React, {createContext, useState, useReducer} from 'react';
import { InstaReducer } from '../reducers/InstaReducer';

export const InstaContext = createContext()

const InstaContextProvider = (props) => {
    const [Insta, dispatch] = useReducer(InstaReducer, [])
    const [_Insta, _dispatch] = useState([]);
    
    return (
        <InstaContext.Provider value={{Insta, dispatch, _Insta, _dispatch}}>
            { props.children }
        </InstaContext.Provider>
    );
}

export default InstaContextProvider;