import React, {createContext, useState, useReducer} from 'react';
import { PostReducer } from '../reducers/PostReducer';

export const PostContext = createContext()

const PostConextProvider = (props) => {
    const [Posts, dispatch] = useReducer(PostReducer, [])
    const [_Posts, _dispatch] = useState([]);

    return (
        <PostContext.Provider value={{Posts, dispatch, _Posts, _dispatch}}>
            { props.children }
        </PostContext.Provider>
    );
}

export default PostConextProvider;