import React, {createContext, useState, useReducer} from 'react';
import { ReviewReducer } from '../reducers/ReviewReducer';

export const ReviewsContext = createContext()

const ReviewContextProvider = (props) => {
    const [Reviews, dispatch] = useReducer(ReviewReducer, [])
    const [_Reviews, _dispatch] = useState([]);
    
    return (
        <ReviewsContext.Provider value={{Reviews, dispatch, _Reviews, _dispatch}}>
            { props.children }
        </ReviewsContext.Provider>
    );
}

export default ReviewContextProvider;